var opts = {
	lines: 16, // The number of lines to draw
	length: 22, // The length of each line
	width: 8, // The line thickness
	radius: 24, // The radius of the inner circle
	corners: 1, // Corner roundness (0..1)
	rotate: 0, // The rotation offset
	direction: 1, // 1: clockwise, -1: counterclockwise
	color: '#327cd0', // #rgb or #rrggbb or array of colors
	speed: 1.2, // Rounds per second
	trail: 100, // Afterglow percentage
	shadow: false, // Whether to render a shadow
	hwaccel: true, // Whether to use hardware acceleration
	className: 'spinner', // The CSS class to assign to the spinner
	zIndex: 2e9, // The z-index (defaults to 2000000000)
	top: '50%', // Top position relative to parent
	left: '50%' // Left position relative to parent
};