$(document).ready(function() { 

	/*****************************************/
	/********** search ***********************/
	/*****************************************/
	$("input[name='404_term']").keydown(function (e) {
	    if (e.keyCode == 13) {
	    	//first stop the form from submitting
	        e.preventDefault();
	        $('.404_search_submit').trigger('click');
	    }
	});

	$("input[name='float_term']").keydown(function (e) {
	    if (e.keyCode == 13) {
	    	//first stop the form from submitting
	        e.preventDefault();
	        if($("input[name='float_term']").val().trim()!=''){
	        	$('.topmenusearch').find('#term').val($("input[name='float_term']").val().replace(/[^\w\s]/gi, ' '));
	        	$('.topmenusearch').submit();
	        }else{
	        	return false;	
	        }
	    }
	});



	$('.404_search_submit').click(function(e){
			 e.preventDefault();
		 	//if the value is not empty then submit the form
	        if($("input[name='404_term']").val().trim()!=''){
	        	$('.topmenusearch').find('#term').val($("input[name='404_term']").val().replace(/[^\w\s]/gi, ' '));
	        	$('.topmenusearch').submit();
	        }else{
	        	return false;	
	        }
	});

});