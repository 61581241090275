$(document).ready(function() {

	// if($('#consent').val()=='enabled' && !$('#crawler').val()){
	// 	$('#consent_topbar').show();
	// }

	// $('#privacy_setting_btn').click(function(){
	// 	$('#consent_detailed_modal').modal({
    //         keyboard: false,
    //         show:true
	// 	});
	// });

	// $('#consent_detailed_modal').on('show.bs.modal', function (e) {

	//   $('#save_consent_options').click(function(){

	//   		//delete all cookies already there
	//   		removeCookies();

	//   		$('#consent_detailed_modal').modal('hide');

	//         $.ajax({
	// 	            type: 	'POST',
	// 	            url: 	base_url+'/consent/choose?token='+$('#user_token').val(),
	//             	data:{
	// 					'analytics':$('#analytics')[0].checked,
	// 					'chat':$('#chat')[0].checked,
	// 					'youtube':$('#youtube')[0].checked/*,
	// 					'remarketing':$('#remarketing')[0].checked*/
	// 				},dataType:   'json',
	// 	            beforeSend : function (){
	// 	            	//display the loader
	// 	            	showLoader();
	// 				},
	// 	            success:  function(json){
	// 	            	if(json.status=='success'){
		            		
	// 	            		//on success redirect to the requested category
	// 	            		location.reload();
    //         			}
	//            	 	}
	//         	});	
	// 	});

	// });

	// $('#let_me_choose_btn').click(function(){
	// 	$('#consent_topbar').hide();
	// 	$('#consent_detailed_modal').modal({
	// 		backdrop: 'static',
    //         keyboard: false,
    //         show:true
	// 	});
	// });


	// $('#consent_continue_btn').click(function(){
	// 	$('#consent_topbar').hide();
	// 	removeCookies();
	// 	$.ajax({
    //         type:       'GET',
    //         url:        base_url+'/consent/agree-all?token='+$('#user_token').val(),
    //         dataType:   'json',
    //         beforeSend : function (){
    //         	//display the loader
    //         	showLoader();
	// 		},
    //         success:  function(json){
	//             	if(json.status=='success'){
    //         		//on success redirect to the requested category
    //         		location.reload();
    //     		}
    //    	 	}
    //     });	
	// });

	$('.bottom_nav_home').click(function(e){
        //detect in it's already the home poge and if yes then just scroll to top
        e.preventDefault();
        if(window.location.pathname=='/'){
            window.scrollTo(0, 0);
        }else{
            showLoader();
            window.location = '/';
        }
    });

    $('.topmenusearch').submit(function(e){
    	if($(this).find('#term').val().trim()==''){
    		e.preventDefault();
    		return false;
    	}
    });

    $('.search-trigger').click(function(e){
    	e.preventDefault();
    	$('.mobile-bar-child').find('.wsnavtoggle').trigger('click');
    	$('.topmenusearch input').focus();
    });

    $('.bottom_nav_chat').click(function(e){
        e.preventDefault();
        Tawk_API.toggle();
    });

    $('.bottom_nav_callback').click(function(e){
    	$('#request_call_back').modal('show');
    });

    $('.bottom_nav_products').click(function(e){
        e.preventDefault();
        showLoader();
        window.location = '/ourproducts';
    });

	// $('#preloader_container').remove();
	// $("body").removeClass("preload");
	$('#menu_button').click(function(){
		$("body").toggleClass("noscroll");
	});


	window.showLoader = function showLoader(){
		if( $("#preloader_container_trans").css('display') == 'none') {
			$('#preloader_container_trans').show();
		}
	}

	window.hideLoader = function hideLoader(){
		$('#preloader_container_trans').hide();
	}

	$('.trigger-cart').click(function(){
		var shit = $('.shoppingcart');

		console.log(shit);

		var shit2 = shit.find('.dropdown_2');
		shit2.toggleClass('quoteshow');
	});
	
	/*****************************************/
	/********** Top search *******************/
	/*****************************************/
	$("input[name='term']").keydown(function (e) {
	    if (e.keyCode == 13) {
	    	//first stop the form from submitting
	        e.preventDefault();
	         //if the value is not empty then submit the form
	        if($("input[name='term']").val().replace(/[^\w\s]/gi, '')!=' '){
	        	$('.topmenusearch').submit();
	        }else{
	        	return false;	
	        }
	    }
	});

	$(".pinned_term").keydown(function (e) {
	    if (e.keyCode == 13) {
	    	//first stop the form from submitting
	        e.preventDefault();
	         //if the value is not empty then submit the form
	        if($("input[name='term']").val().replace(/[^\w\s]/gi, '')!=' '){
	        	$('.pinned_topmenusearch').submit();
	        }else{
	        	return false;	
	        }
	    }
	});

	$('.top_search_btn').on('click', function(e) {
		e.preventDefault();
		//if the value is not empty then submit the form
        if($("input[name='term']").val().replace(/[^\w\s]/gi, '')!=' '){
        	$('.topmenusearch').submit();
        }else{
        	return false;	
        }
	});
	$('#pinned-desktop-search-btn').on('click', function(e) {
		e.preventDefault();
		//if the value is not empty then submit the form
        if($("input[name='term']").val().replace(/[^\w\s]/gi, '')!=' '){
        	$('.pinned_topmenusearch').submit();
        }else{
        	return false;	
        }
	});
	$('#desktop-search-btn').on('click', function(e) {
		e.preventDefault();
		//if the value is not empty then submit the form
        if($("input[name='term']").val().replace(/[^\w\s]/gi, '')!=' '){
        	$('.topmenusearch').submit();
        }else{
        	return false;	
        }
	});

	/**********************************************************************************/
	/********** Saving category parent in the session on son click *******************/
	/**********************************************************************************/
	$('.main_menu_links a').click(function(e){
		//prevent default
		e.preventDefault();
		//display the spinner
		showLoader();
		//then redirect to the requested category
		window.location.href = base_url+'/'+$(this).data('slug'); 

	});

	$('.categories_list li a').click(function(e){
		e.preventDefault();
		//get parent id
		parent_id = $(this).data('parent');
		son_id = $(this).data('id');
		slug = $(this).data('slug');
		//send an ajax call to store it as the current parent 
		$.ajax({
		            type:       'POST',
		            url:        base_url+'/category/save_selected_parent',
		            data:       {parent_id:parent_id,son_id:son_id},
		            dataType:   'json',
		            beforeSend : function (){
		            	//display the loader
		            	showLoader();
					},
		            success:  function(json){
			            	if(json.status=='success'){
		            		//on success redirect to the requested category
		            		window.location.href = base_url+'/'+slug; 
	            		}
	           	 	}
	        	});	
		

	});

	/**************************************************************/
	/******************************* Login auth *******************/
	/**************************************************************/

	//check if the enter button is clicked in any of the two feilds then submit the form
	$('#top_login_form').find(".enter_submit").keyup(function (e) {
		if (e.keyCode == 13) {
	        top_login_form_validation();
	    }
	});

	//validate login form
	$('#top_login_form').find('#login_submit').click(function(event){
		event.preventDefault();
		top_login_form_validation();
		
	});


	function top_login_form_validation()
	{
		//clear the error feild
		$('#top_login_form').find('.login_error').empty();

		if($("#top_login_form").valid()){	
   			
   			//get the username and password feilds values
   			username = $('#top_login_form').find('input[name="username"]').val();
   			password = $('#top_login_form').find('input[name="password"]').val();
   			remember_me = $('#top_login_form').find('input[name="remember_me"]').prop('checked');

   			data = {username:username,password:password,remember_me:remember_me}

   			$.ajax({
			            type:       'POST',
			            url:        base_url+'/users/auth_user',
			            data:data,
			            dataType:   'json',
			            beforeSend: function()
			            {
			            	//disable the login button
			            	$('#top_login_form').find('#login_submit').addClass('disable_btn');
			            	$('#top_login_form').find('#login_submit').attr('disabled','disabled');
			            	//and show the small loader
			            	$('#top_login_form').find('.login_small_loader').show();
			            },
			            success:    function(json)
			            {
			            	if(json.status='success'){
			            		//hide the loader
				            	$('#top_login_form').find('.login_small_loader').hide();
				            	//if everything went well reload the page
				            	if(json.error==''){
				            		//reload the page
				            		location.reload();
				            	}else{
				            		//re-enable the login button again
				            		$('#top_login_form').find('#login_submit').removeClass('disable_btn');
				            		$('#top_login_form').find('#login_submit').removeAttr('disabled');
				            		//display the error in the error feild
				            		$('#top_login_form').find('.login_error').html(json.error);
				            	}
			            	}

			            }
			        });
			  
			
   		}else{
   			//validate the form
   			validator.form();	
   		}
	}


	//validation and rules
	 var validator = $('#top_login_form').validate({
	 		onsubmit: false,
	        rules: {
	            username: {
	                required: true
	            }
	            ,password: {
	                required: true
	            }
	        },
	        highlight: function (element) {
	            $(element).closest('input').addClass('error');
	        },
	        unhighlight: function (element) { // un-hightlight error inputs
			  $(element)
			  .closest('input').removeClass('error'); 
			},
	        success: function (element) {
	            element.addClass('valid')
	                .closest('.input').removeClass('error');
	                
	        }
	});

		 /**************************************************************/
	 	 /******************** Newsletter subscriber *******************/
		 /**************************************************************/


		 /// newsletter form validation
	 	var newsletter_validator = $('#newsletter_subscriber').validate({
	 		onsubmit:false,
	        rules: {
	        	subscribe_email: {
	                required: true,
	                email: true
	            }
	        },
	        errorPlacement: function(error, element) {
			    error.appendTo(".newsletter_error");
			    $('.newsletter_error').show();
			  },
	        highlight: function (element) {
	            $(element).closest('.form-group').addClass('has-error');
	        },
	        unhighlight: function (element) { // un-hightlight error inputs
			  $(element)
			  .closest('.form-group').removeClass('has-error'); 
			},
	        success: function (element) {
	            element.addClass('valid')
	                .closest('.form-group').removeClass('has-error');
	                
	        }
	    });

	    $('input[name="subscribe_email"]').keydown(function(e){
		 	if (e.keyCode == 13) {
		 		$('.newsletter_subscribe').trigger('click');
		 	}
		 });


		 $('.newsletter_subscribe').click(function(e){
		 	e.preventDefault();
		 	if($("#newsletter_subscriber").valid()){
		 		add_subscriber_to_newsletter($('input[name="subscribe_email"]').val());
		 	}else{
		 		newsletter_validator.form();
		 	}
		 });



		 /*********************************/
		 /******* Request Callback *******/
		 /*******************************/

		 //when the request call back button is clicked
		 $('.request_callback').click(function(){
		 		//display popup
		 		$('#request_call_back').modal('show');
		 });

		 //when a callback request is submitted
		 $('.submit_request_callback').click(function(e){
		 		e.preventDefault();
		 		//validate the form
		 		if($("#request_callback_form").valid()){
		 				//call function which will add the record to herbert
		 				//assign an agent 
		 				//and send email notification to the agent
		 				user_name = $('#rf_name').val();
		 				telephone = $('#rf_telephone').val();
		 				company = $('#rf_company').val();
		 				time = $('#rf_time').val();
		 				token = $('#user_token').val();

		 				data = {name:user_name,telephone:telephone,company:company,time:time,token:token,brand:$('#brand_key').val()}

						$.ajax({
					            type:       'POST',
					            url:        $('#api_url').val()+'callback/request',
					            data: data,
					            dataType:   'json',
								headers:{
									'authorization':$('#api_key').val()
								},
					            beforeSend : function (){
					            	user_name = $('#rf_name').val('');
					 				telephone = $('#rf_telephone').val('');
					 				company = $('#rf_company').val('');
					 				time = $('#rf_time').val('');
					            	//clear and hide the modal window
						            $('#request_call_back').modal('hide');
					            	//display the loader
					            	showLoader();
								},
					            success:    function(json){
									if(json.success==1){
										//stop spinner
										hideLoader();
										//trigger google tracking
										// ga('send', 'pageview', {page: '/callback.html'});
										gtag('event', 'page_view', {page_location: Config.base_url + 'callback.html'});
										//display success alert
										display_top_alert('success','Request sent successfully!');
									}else{
										//stop spinner
										hideLoader();
										//display fail alert
										display_top_alert('danger','Request to callback was unsuccessful!');
									}
						            }

						          });
		 			}else{
		   			//validate the form
		   			request_validator.form();	
		   		}
		 });

	 //validate the callback form before
	 var request_validator = $('#request_callback_form').validate({
	 		onsubmit: false,
	        rules: {
		            rf_name: {
		                required: true
		            },
		            rf_telephone: {
		                required: true,
		                digits: true
		            }
	            },
	        highlight: function (element) {
	            $(element).closest('input').addClass('error');
	        },
	        unhighlight: function (element) { // un-hightlight error inputs
			  $(element)
			  .closest('input').removeClass('error'); 
			},
	        success: function (element) {
	            element.addClass('valid')
	            .closest('.input').removeClass('error');
	        }
	});


	/*===============================================
	=            Brochure request Button            =
	===============================================*/
	/*$('.brochure').click(function(){
		$('#brochure_modal').modal('show');
	});*/

	 $('.submit_brochure_request').click(function(e){
 		e.preventDefault();
 		//validate the form
 		if($("#request_brochure_form").valid()){
 			//ajax call to send email to marketing with details
 			data = {
 				name:$('#b_name').val(),
 				company:$('#b_company').val(),
 				telephone:$('#b_telephone').val(),
 				email:$('#b_email').val(),
 				address_1:$('#b_address_1').val(),
 				address_2:$('#b_address_2').val(),
 				postcode:$('#b_postcode').val(),
 				brochure:$('input[name=brochure]:checked').val()
 			}
 			$.ajax({
		            type:       'POST',
		            url:        base_url+'/home/send_brochure_request',
		            data: data,
		            dataType:   'json',
		            beforeSend : function (){
		            	$('#b_name').val('');
		 				$('#b_company').val('');
		 				$('#b_telephone').val('');
		 				$('#b_email').val('');
		 				$('#b_address_1').val('');
		 				$('#b_address_2').val('');
		 				$('#b_postcode').val('');
		            	//clear and hide the modal window
			            $('#brochure_modal').modal('hide');
		            	//display the loader
		            	showLoader();
					},
		            success:    function(json){
			            	if(json.status=='success'){
				 				//stop spinner
			            		hideLoader();
			            		//display success alert
			            		display_top_alert('success','Thanks for your brochure request. We will dispatch a copy as soon as possible.');
			            	}
			            }

			          });
 		}else{
   			//validate the form
   			brochure_validator.form();	
   		}
   	});
	
	//validate the brochure form before
	 var brochure_validator = $('#request_brochure_form').validate({
	 		onsubmit: false,
	        rules: {
		            b_name: {
		                required: true
		            },
		            b_email: {
		                required: true,
		                email:true
		            },
		            b_telephone: {
		                required: true,
		                digits: true
		            },
		             b_address_1: {
		                required: true
		            },
		            b_postcode:{
		            	required: true
		            }

	            },
	        highlight: function (element) {
	            $(element).closest('input').addClass('error');
	        },
	        unhighlight: function (element) { // un-hightlight error inputs
			  $(element)
			  .closest('input').removeClass('error'); 
			},
	        success: function (element) {
	            element.addClass('valid')
	            .closest('.input').removeClass('error');
	        }
	});

});


function removeCookies() {

	var domains = [	'.bing.com',
					'.doubleclick.net',
					'.furniturehireuk.com',
					'.google.co.uk',
					'.google.com',
					'.tawk.to',
					'bat.bing.com',
					'www.furniturehireuk.com',
					'www.google.co.uk',
					'va.tawk.to',
					'.youtube.com',
					'.furniturehireuk.local',
					'furniturehireuk.local',
					''];
	var cookies = Cookies.get();
	for (var cookie in cookies) {
		if(cookie!='XSRF-TOKEN'){
			for(key in domains){
		   		Cookies.remove(cookie, { path: '/', domain: domains[key]  }); 
			}
		}
	}
}

function add_subscriber_to_newsletter(email)
{
	data = {email:email}
	$.ajax({
            type:       'POST',
            url:        base_url+'/home/subscribe_to_news_letter',
            data: data,
            dataType:   'json',
            beforeSend : function (){
            	//display the loader
            	showLoader();
			},
            success:    function(json){
	            	if(json.status=='success'){
	            		 $('input[name="subscribe_email"]').val('');
	            		hideLoader();
	            		display_top_alert('success','You subscribed to our Newsletter Successfully!');
	            	}
	            }

	          });
}


//postcode validation function 
function checkPostCode (toCheck) {

  // Permitted letters depend upon their position in the postcode.
  var alpha1 = "[abcdefghijklmnoprstuwyz]";                       // Character 1
  var alpha2 = "[abcdefghklmnopqrstuvwxy]";                       // Character 2
  var alpha3 = "[abcdefghjkpmnrstuvwxy]";                         // Character 3
  var alpha4 = "[abehmnprvwxy]";                                  // Character 4
  var alpha5 = "[abdefghjlnpqrstuwxyz]";                          // Character 5
  var BFPOa5 = "[abdefghjlnpqrst]";                               // BFPO alpha5
  var BFPOa6 = "[abdefghjlnpqrstuwzyz]";                          // BFPO alpha6
  
  // Array holds the regular expressions for the valid postcodes
  var pcexp = new Array ();
  
  // BFPO postcodes
  pcexp.push (new RegExp ("^(bf1)(\\s*)([0-6]{1}" + BFPOa5 + "{1}" + BFPOa6 + "{1})$","i"));

  // Expression for postcodes: AN NAA, ANN NAA, AAN NAA, and AANN NAA
  pcexp.push (new RegExp ("^(" + alpha1 + "{1}" + alpha2 + "?[0-9]{1,2})(\\s*)([0-9]{1}" + alpha5 + "{2})$","i"));
  
  // Expression for postcodes: ANA NAA
  pcexp.push (new RegExp ("^(" + alpha1 + "{1}[0-9]{1}" + alpha3 + "{1})(\\s*)([0-9]{1}" + alpha5 + "{2})$","i"));

  // Expression for postcodes: AANA  NAA
  pcexp.push (new RegExp ("^(" + alpha1 + "{1}" + alpha2 + "{1}" + "?[0-9]{1}" + alpha4 +"{1})(\\s*)([0-9]{1}" + alpha5 + "{2})$","i"));
  
  // Exception for the special postcode GIR 0AA
  pcexp.push (/^(GIR)(\s*)(0AA)$/i);
  
  // Standard BFPO numbers
  pcexp.push (/^(bfpo)(\s*)([0-9]{1,4})$/i);
  
  // c/o BFPO numbers
  pcexp.push (/^(bfpo)(\s*)(c\/o\s*[0-9]{1,3})$/i);
  
  // Overseas Territories
  pcexp.push (/^([A-Z]{4})(\s*)(1ZZ)$/i);  
  
  // Anguilla
  pcexp.push (/^(ai-2640)$/i);

  // Load up the string to check
  var postCode = toCheck;

  // Assume we're not going to find a valid postcode
  var valid = false;
  
  // Check the string against the types of post codes
  for ( var i=0; i<pcexp.length; i++) {
  
    if (pcexp[i].test(postCode)) {
    
      // The post code is valid - split the post code into component parts
      pcexp[i].exec(postCode);
      
      // Copy it back into the original string, converting it to uppercase and inserting a space 
      // between the inward and outward codes
      postCode = RegExp.$1.toUpperCase() + " " + RegExp.$3.toUpperCase();
      
      // If it is a BFPO c/o type postcode, tidy up the "c/o" part
      postCode = postCode.replace (/C\/O\s*/,"c/o ");
      
      // If it is the Anguilla overseas territory postcode, we need to treat it specially
      if (toCheck.toUpperCase() == 'AI-2640') {postCode = 'AI-2640'};
      
      // Load new postcode back into the form element
      valid = true;
      
      // Remember that we have found that the code is valid and break from loop
      break;
    }
  }
  
  // Return with either the reformatted valid postcode or the original invalid postcode
  if (valid) {return postCode;} else return false;
}



//function that displays a top success message
function display_top_alert(type,message)
{
	//remove any other alert already on the screen 
	$('.alert_box').remove();
	//add the alert message
	$('body').prepend('<div class="alert_box '+type+' r_corners relative fs_medium"><b>'+message+'</b><i class="icon-cancel close_alert_box tr_all translucent circle t_align_c"></i></div>');
	//then fade it in
	$('.alert_box').fadeIn('slow');
	//trigger a timer with will fade the alert out in 5 sec
	setTimeout(function(){$('.alert_box').fadeOut('slow')}, 5000);
}



/*****************************************/
/****** Scroll to element on page ********/
/*****************************************/
function scroll_to_element(element_id)
{
	$('html, body').animate({
        scrollTop: $('#'+element_id).offset().top
    }, 500);
}

	 